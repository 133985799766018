<div class="app-auth-full-container">

  <img class="app-auth-logo -externalPage"
       *ngIf="productTheme"
       [src]="productTheme.headerLogo">
  <a class="app-header-byFlexitime"
     *ngIf="product_value !== 'FLEXITIME' && product_value !== 'DROPPAH'"
     href="https://www.flexitime.works"
     target="_blank">
    <img src="assets/product-imgs/flexitime/FromFlexiTime_Horiz.svg" />
  </a>

  <!-- *ngIf="type !== 'INVITE'" -->

  <div class="app-auth-pageTitleContainer"
       *ngIf="product_value !== 'DROPPAH'"
       [ngClass]="{ '-noTitle': !displayConfig.pageTitle && !displayConfig.pageDescription,
                    '-justTitle': !!displayConfig.pageTitle && !displayConfig.pageDescription,
                    '-titleAndDesc': !!displayConfig.pageTitle && !!displayConfig.pageDescription,
                    '-resetPasswordTitle': forgottenPassword }">
    <h2 class="app-auth-pageTitle"
        *ngIf="!!displayConfig.pageTitle">
      {{displayConfig.pageTitle}}
    </h2>

    <h4 *ngIf="!!displayConfig.pageDescription && !forgottenPassword">
      {{displayConfig.pageDescription}}
    </h4>
  </div>

  <div class="app-auth-contentContainer"
       [ngClass]="{ '-existingUserPassword': !errorMessage && existingUserWithPassword && type !== 'UPDATE VERIFICATION' && !!displayConfig.forgotPasswordButton }">

    <div class="-productGraphic"
         *ngIf="product_value !== 'DROPPAH'">
      <img *ngIf="productTheme?.graphicLeft"
           [src]="productTheme.graphicLeft">
    </div>

    <div class="app-auth-card -signup-or-login-page"
         [ngClass]="{'-whiteCard': product_value !== 'DROPPAH'}"
         [style.height]="auth_card_height !== null ? auth_card_height + 'px' : ''"
         *ngIf="!forgottenPassword">

      <spinner *ngIf="submitting">
      </spinner>

      <div class="app-auth-card-innerContainer"
           #authCardInner>

        <div class="app-infoPanel"
             *ngIf="existingUserWithPassword && type !== 'UPDATE VERIFICATION'">
          <div>
            An
            <span>existing FlexiTime account
              <tooltip-popover [smallFont]="true"
                               [invertColours]="productTheme.displayName === 'Droppah'"
                               [tooltipContent]="existingAccountTooltipContent">
              </tooltip-popover>
            </span>
            is associated with this email address.
            Sign in to continue. <a target="_blank"
               href="https://support.flexitime.works/hc/en-us/articles/5432799119375-Registering-with-an-existing-password">Need
              help?</a>
          </div>
        </div>

        <div class="app-auth-card-inner"
             [ngClass]="{ '-submitting': !!submitting }">

          <div class="app-auth-droppah-mobile-graphic"
               *ngIf="product_value === 'DROPPAH' && displayConfig.disableIntegrations">
            <img *ngIf="productTheme?.graphicRight"
                 [src]="productTheme.graphicRight">
          </div>

          <div *ngIf="product_value === 'DROPPAH'"
               class="app-auth-droppah-content -hide-on-mobile">

            <img class="app-auth-logo-droppah"
                 *ngIf="productTheme"
                 [src]="productTheme.headerLogo">
            <h1>Drop the hassle, <br> keep the hustle.</h1>

          </div>



          <div *ngIf="!this.additional_data?.candidate_flag">
            <div class="app-auth-integration-container">
              <ng-container *ngFor="let integration of loginIntegrations">
                <integration-button *ngIf="(!existingUser || activeIntegrations.has(integration.integration_type)) && !displayConfig.disableIntegrations"
                                    [loginIntegration]="integration"
                                    [isDroppah]="product_value === 'DROPPAH'"
                                    [customPrefix]="displayConfig.integrationText"
                                    (buttonPressed)="integrationButton($event.integration_type)">
                </integration-button>
              </ng-container>
            </div>


            <div class="app-integrationsDivider"
                 *ngIf="(!existingUser || activeIntegrations.size) && !displayConfig.disableIntegrations && product_value !== 'DROPPAH'">
              <div class="app-integrationsDivider-text"
                   *ngIf="displayConfig.password">
                or sign {{!!existingUser ? 'in' : 'up'}} with email
              </div>
              <hr class="app-integrationsDivider-line" />
            </div>

            <div *ngIf="(!existingUser || activeIntegrations.size) && !displayConfig.disableIntegrations && product_value === 'DROPPAH'"
                 class="app-integrationsDivider">
              <hr class="leftDivider">
              <div>
                Or sign up with email
              </div>
              <hr class="rightDivider">
            </div>

          </div>

          <form class="app-auth-form"
                (ngSubmit)="signUp()">

            <div class="app-auth-errorMessage"
                 *ngIf="errorMessage">
              <i class="ion ion-ios-warning sm-icon"></i> {{errorMessage}}
            </div>

            <div class="fieldLabel">
              Email Address
            </div>
            <input class="app-input -inputLarge -disabled"
                   autocomplete="username"
                   [disabled]="true"
                   [value]="email" />

            <div class="fieldLabel"
                 *ngIf="!!displayConfig.fullName">
              Full Name
            </div>
            <input class="app-input -inputLarge"
                   type="text"
                   id="name"
                   name="name"
                   [placeholder]="product_value === 'DROPPAH' ? 'Full Name' : ''"
                   [hidden]="!displayConfig.fullName"
                   [(ngModel)]="fullName"
                   (ngModelChange)="hideError()">


            <div class="fieldLabel"
                 *ngIf="displayConfig.password">
              Password
              <span class="app-auth-forgot"
                    (click)="resetPassword(true)"
                    [hidden]="!displayConfig.forgotPasswordButton">
                Forgot Password?
              </span>
            </div>

            <div class="password-field">
              <input class="app-input -inputLarge"
                     [type]="showPassword ? 'text' : 'password'"
                     id="password"
                     name="password"
                     [placeholder]="product_value === 'DROPPAH' ? 'Password' : ''"
                     autocomplete="new-password"
                     [hidden]="!displayConfig.password"
                     [(ngModel)]="password"
                     (ngModelChange)="hideError()">
              <span class="-showPasswordIcon"
                    [hidden]="!displayConfig.password"
                    (click)="toggleShowPassword($event)">
                <i class="ion"
                   [ngClass]="!showPassword ? 'ion-md-eye-off' : 'ion-md-eye'"></i>
              </span>
            </div>
            <div class="fieldLabel -mobile-only"
                 *ngIf="!!displayConfig.password && product_value === 'DROPPAH'">
              <span class="app-auth-forgot"
                    (click)="resetPassword(true)"
                    [hidden]="!displayConfig.forgotPasswordButton">
                Forgot Password?
              </span>
            </div>

            <div class="app-auth-accessAgreement"
                 *ngIf="displayConfig.partnerTsandCs && displayConfig.submitButton">
              <ph-checkbox [(ngModel)]="partnerTermsAgreed"
                           [ngModelOptions]="{ standalone: true }">
              </ph-checkbox>
              <div>
                I agree with the terms described in the FlexiTime Partner Agreement.
                If you need a copy of the agreement, please email
                <a href="mailto:support@flexitime.works"
                   target="_top"
                   [ngStyle]="{ 'color': productTheme.colour }">
                  support@flexitime.works
                </a>.
              </div>
            </div>

            <!-- <div class="app-auth-accessAgreement"
          *ngIf="displayConfig.userTsandCs && displayConfig.submitButton">
        <ph-checkbox [(ngModel)]="userTermsAgreed"
                    [ngModelOptions]="{ standalone: true }">
        </ph-checkbox>

        <span>I acknowledge that I have read and agree to FlexiTime's
          <a href="https://www.flexitime.works/subscription-agreement?reload"
            target="_blank">
            Subscription Agreement
          </a>
        </span>

      </div> -->

            <button class="app-auth-submit"
                    type="submit"
                    [disabled]="submitting || !password"
                    [hidden]="!displayConfig.submitButton">
              <span>{{submitting ? displayConfig.submitButtonLabelSubmitting :
                displayConfig.submitButtonLabel}}</span>
            </button>
          </form>
          <div *ngIf="!loading && !forgottenPassword && type === 'VERIFICATION' && !existingUser"
               class="app-auth-textLink"
               (click)="goLogin()">
            Already have an account? Sign in here
          </div>

          <div class="app-footer-droppah">


            <a class="app-footer-byFlexitime"
               *ngIf="product_value === 'DROPPAH'"
               href="https://www.flexitime.works"
               target="_blank">
              <img [src]="productTheme.fromFlexiTimeLogo">
            </a>
            <app-footer-user-agreements [support_centre_link]="productTheme.supportCentreURL ? productTheme.supportCentreURL : null"
                                        *ngIf="product_value === 'DROPPAH'">
            </app-footer-user-agreements>
          </div>


        </div>
      </div>
    </div>

    <div class="app-auth-card -signup-or-login-page"
         *ngIf="forgottenPassword"
         [ngClass]="{'-whiteCard': product_value !== 'DROPPAH'}"
         [style.height]="auth_card_height !== null ? auth_card_height + 'px' : ''">

      <spinner *ngIf="loading">
      </spinner>

      <div class="app-auth-card-inner"
           #authCardInner
           [ngClass]="{ '-submitting': loading }">

        <form class="app-auth-form"
              *ngIf="forgottenPassword"
              (ngSubmit)="sendPasswordReset()">

          <h1 class="app-auth-pageTitle"
              *ngIf="product_value === 'DROPPAH'">
            Reset Password
          </h1>

          <div class="fieldLabel">
            Email Address
          </div>
          <input class="app-input -inputLarge -disabled"
                 [disabled]="true"
                 [value]="email" />

          <button class="app-auth-submit"
                  [disabled]="loading"
                  id="reset">
            Send Email
          </button>

        </form>

        <div class="app-auth-textLink"
             (click)="resetPassword(false)">
          Back to sign in
        </div>

      </div>
    </div>

    <div class="-productGraphic">
      <img *ngIf="productTheme?.graphicRight"
           [src]="productTheme.graphicRight">
    </div>
  </div>

</div>


<app-footer-user-agreements [support_centre_link]="productTheme.supportCentreURL ? productTheme.supportCentreURL : null"
                            *ngIf="product_value !== 'DROPPAH'">
</app-footer-user-agreements>


<div class="app-auth-mobileGraphicFooter"
     *ngIf="!!productTheme.graphicMobileFooterFull && productTheme.displayName !== 'FlexiTime'">
  <img [src]="productTheme.graphicMobileFooterFull">
</div>
