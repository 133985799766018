import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { find, cloneDeep } from 'lodash-es';

import { DateToTimePipe } from 'src/app/pipes/date-to-time/date-to-time.pipe';

import { AccountService } from '../../../services/account/account.service';
import { ProductService } from '../../../services/product/product.service';
import { ProductTheme, ProductName, ProductValue, NZRegion, ProductValueAndName, AppTab, DestinationValue, CountryProvince } from './../../../app.types';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { APISearchComponent } from 'src/app/components/api-search/api-search.component';
import { UserService } from '../../../services/user/user.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TimeUtilService } from 'src/app/services/time-util/time-util.service';
import { PhEmployee } from 'src/app/models/ph-employee/ph-employee';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';

import { Account } from 'src/app/models/account/account';
import { StateDataService } from 'src/app/services/state-data/state-data.service';
import { PostableObjectError } from 'src/app/interfaces/postable-object.inerface';
import { trigger, style, animate, transition } from '@angular/animations';
import { SortUtilService } from 'src/app/services/sort-util/sort-util.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';


type PayHeroActionButton = {
  value: ('ADD_EMPLOYEES' | 'CONNECT_IRD' | 'INVITE_ACCOUNTANT' | 'TIMESHEETS' | 'CREATE_PAY'),
  label: string,
  icon_class: string,
  redirect_to_product_page: string
}

type PayCycleValue = ('WEEKLY' | 'MONTHLY' | 'FORTNIGHTLY' | 'FOUR_WEEKLY');

type PayCycleInputs = {
  value: PayCycleValue,
  label: string
}

type TabValue = (
  'ALL_PRODUCTS' | 'COMPANY_INFO' | 'PAY_CYCLE' | 'ADD_EMPLOYEES' | 'COMPLETION'
);

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]),
  ]
})
export class AccountCreateComponent implements OnInit {

  @ViewChild('companyNZBNSearch') companyNZBNSearch: APISearchComponent;

  readonly regions: NZRegion[] = CoreUtilService.nz_regions;
  readonly is_partner: boolean = this.userService.is_partner;

  //todo: check if need this for droppah?
  readonly employee_options: string[] = ['1 - 10', '11 - 25', '26 - 50', '51 - 100', '101 - 500', '500+'];
  readonly partner_karmly_warning_message: string = this.accountService.partner_karmly_warning_message;

  readonly product_dropdown: ProductValueAndName[] = this.accountService.getAvailableProductsForAccountCreation();
  selected_product: ProductValueAndName = null;

  global_product: ProductValue;

  existing_account: Account;

  marketing_additional_data: string;

  product_theme: ProductTheme;
  show_demo_option: boolean = false;
  loading: boolean = true;

  payhero_session = {
    company_code: '',
    session_key: ''
  }; //TODO

  is_mobile: boolean = CoreUtilService.is_mobile;

  action_buttons: PayHeroActionButton[] = [
    {
      value: 'CREATE_PAY',
      label: 'Create a pay',
      icon_class: 'ion ion-ios-send',
      redirect_to_product_page: 'app.pay.review'
    },
    {
      value: 'INVITE_ACCOUNTANT',
      label: 'Invite your accountant or bookkeeper',
      icon_class: 'bi bi-person-circle',
      redirect_to_product_page: 'app.settings.uac'
    },
    {
      value: 'TIMESHEETS',
      label: 'Enter timesheets',
      icon_class: 'bi bi-clock-fill',
      redirect_to_product_page: 'app.time.sheets'
    },
    {
      value: 'CONNECT_IRD',
      label: 'Connect to Inland Revenue',
      icon_class: 'bi bi-arrow-repeat',
      redirect_to_product_page: 'app.settings.integrations'
    },
    {
      value: 'ADD_EMPLOYEES',
      label: 'Add more employees or contractors',
      icon_class: 'bi bi-people-fill',
      redirect_to_product_page: 'app.settings.employee.view'
    }
  ];

  pay_cycles_map: Record<PayCycleValue, PayCycleInputs> = {
    'WEEKLY': {
      value: 'WEEKLY',
      label: 'Weekly',
    },
    'FORTNIGHTLY': {
      value: 'FORTNIGHTLY',
      label: 'Fortnightly',
    },
    'FOUR_WEEKLY': {
      value: 'FOUR_WEEKLY',
      label: 'Four Weekly'
    },
    'MONTHLY': {
      value: 'MONTHLY',
      label: 'Monthly',
    }
  };
  pay_cycles: PayCycleInputs[] = [this.pay_cycles_map.WEEKLY, this.pay_cycles_map.FORTNIGHTLY, this.pay_cycles_map.FOUR_WEEKLY, this.pay_cycles_map.MONTHLY];

  selected_pay_cycle: PayCycleInputs = this.pay_cycles[0];

  week_labels = TimeUtilService.getWeekLabels();
  selected_pay_day = null;
  selected_day_display: string;

  pay_period_start: Date;
  next_pay_day: Date;
  pay_period_end: Date;

  //next pay day for monthly pay cycle is either day of week or date
  //e.g first Sunday of every month / 2nd of every month
  ordinal_day_of_week: boolean = false;
  ph_employees: PhEmployee[] = [];

  pay_date: Date;

  pay_cycle_key: number = null;

  //existing stuff
  company_name: string = '';
  company_nzbn: string = '';
  company_industry_classification: string = null;
  selected_region: NZRegion = null;
  ird_number: string = '';
  number_of_employees: string = null;
  contact_phone: string = '';

  ird_number_valid: boolean = false;

  industry_map: Record<number, string> = null;
  anzsic_class_map: Record<string, number> = null;
  selected_industry_key: number = null;
  selected_business_type: string = null;
  industries: string[];
  business_types: string[] = null;

  nzbn_access_token: string;
  nzbn_minimum_search_term_length: number = 3;
  company_nzbn_loading: boolean = false;
  number_nzbn_loading: boolean = false;

  user_terms_agreed: boolean = false;


  droppah_top_indusrties: string[] = this.accountService.getDroppahTopIndustries();

  slide_map: Record<TabValue, AppTab> = {
    ALL_PRODUCTS: {
      value: 'ALL_PRODUCTS',
      label: ''
    },
    COMPANY_INFO: {
      value: 'COMPANY_INFO',
      label: 'Company Information',
      required_fields: ['company_name']
    },
    PAY_CYCLE: {
      value: 'PAY_CYCLE',
      label: 'Pay Cycle',
      required_fields: ['selected_pay_day', 'pay_period_start', 'next_pay_day']
    },
    ADD_EMPLOYEES: {
      value: 'ADD_EMPLOYEES',
      label: 'Add Employees'
    },
    COMPLETION: {
      value: 'COMPLETION',
      label: 'Completion'
    }
  };
  slides: AppTab[] = [
    this.slide_map.ALL_PRODUCTS,
    this.slide_map.COMPANY_INFO,
    this.slide_map.PAY_CYCLE,
    this.slide_map.ADD_EMPLOYEES,
    this.slide_map.COMPLETION
  ];
  //slides only used in payhero
  selected_slide = null;

  continue_pressed: boolean = false;

  country_provinces: CountryProvince[] = [];
  selected_province: CountryProvince = null;
  selected_province_name: string = null;

  constructor(
    private accountService: AccountService,
    private productService: ProductService,
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private userService: UserService,
    private modalService: ModalService,
    private authService: AuthService,
    private dateToTimePipe: DateToTimePipe,
    private stateDataService: StateDataService,
    private redirectService: RedirectService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.company_name = this.uiRouterGlobals.params.company_name || '';

    this._initMarketingAdditionalData();

    if (!!this.uiRouterGlobals.params.product) {
      const product_value = this.uiRouterGlobals.params.product.toUpperCase();

      this.selected_slide = this.slides[1];

      this.productService.current_product = product_value;
      this._initSelectedProduct(product_value);
    }
    else {
      this.selected_slide = this.slides[0];
    }

    this.global_product = this.productService.current_product;
    this.product_theme = this.productService.product_theme;


    this.payhero_session = this.uiRouterGlobals.params.product_session;

    // may have an existing account if part-way through onboarding
    this._initExistingAccount(this.uiRouterGlobals.params.company_product_key || null);

    this.country_provinces = this.subscriptionService.subscription?.country?.country_provinces || [];

    // Try to auto create an account - will only succeed if state param is passed in & is karmly
    this.autoCreateNewAccount()
      .then(() => { })
      .catch(() => {
        //otherwise continue setting up account creation form

        this.productService.setTitle('Create Account');

        // Get the list of available industries
        this.industry_map = this.accountService.getIndustryMap();
        this.anzsic_class_map = this.accountService.getANZSICClassMap();
        this.industries = Object.values(this.industry_map);
        this.business_types = this.accountService.getBusinessTypes();

        this.accountService.getNZBNAccessToken()
          .then(access_token => {
            this.nzbn_access_token = access_token;
          });

        this.loading = false;

        setTimeout(() => {
          if (!this.company_name && !this.loading) {
            document.getElementById('companyName')?.focus();
          }
        });
      });
  }

  private _initSelectedProduct(product_value: ProductValue) {
    if (this.productService.productIsValid(product_value) && product_value !== 'FLEXITIME') {
      this.selectProduct(find(this.product_dropdown, { product_value }));
    }
  }

  private _initMarketingAdditionalData() {
    this.marketing_additional_data = this.uiRouterGlobals.params.marketing_additional_data || null;
    this.accountService.marketing_additional_data = this.marketing_additional_data;
  }

  private _initExistingAccount(company_product_key: number = null) {
    if (!company_product_key) {
      company_product_key = this.stateDataService.getCachedComponentSessionData(
        'AccountCreateComponent',
        'existingCompanyProductKey'
      );
    }

    this.existing_account = this.accountService.getAccount(company_product_key, true);
    if (!this.existing_account) return;

    this.stateDataService.cacheComponentSessionData(
      'AccountCreateComponent',
      'existingCompanyProductKey',
      company_product_key
    );

    //initialise session if we haven't just created the account
    if (!!this.existing_account && !this.payhero_session) {
      this._initSelectedProduct('PAYHERO');
      const ignore_mobile_device = this.selected_product.product_value === 'PAYHERO';
      this.productService.startNewSession(this.existing_account.company_product_key, this.selected_product.product_value, false, ignore_mobile_device, null)
        .then((session) => {
          this.payhero_session = session;
          this.selectFirstIncompleteStep(this.existing_account.incomplete_onboarding_steps);
        })
        .catch((err) => this.modalService.errorModal(err.message));
    } else {
      this.selectFirstIncompleteStep(this.existing_account.incomplete_onboarding_steps);
    }

    // If existing account, setup company name
    this.company_name = this.existing_account.company_name;
  }

  private _getErrors(): PostableObjectError[] {
    const errors = [];

    if (!this.company_name) {
      errors.push({
        error_path: 'company_name',
        error_message: 'Company name required'
      });
    }
    if (!this.selected_pay_day && this.selected_pay_cycle.value === 'WEEKLY') {
      errors.push({
        error_path: 'selected_pay_day',
        error_message: 'Pay day is required'
      });
    }
    if (!this.pay_period_start) {
      errors.push({
        error_path: 'pay_period_start',
        error_message: 'Start of next pay period required'
      });
    }
    if (!this.next_pay_day && this.selected_pay_cycle.value === 'MONTHLY') {
      errors.push({
        error_path: 'next_pay_day',
        error_message: 'Next pay day required'
      });
    }

    return errors;
  }

  private _validInputs(): boolean {
    return !this._getErrors()
      .map(i => i.error_path)
      .some(i => this.selected_slide.required_fields?.includes(i));
  }

  selectFirstIncompleteStep(incomplete_onboarding_steps: any[]) {
    if (find(incomplete_onboarding_steps, (step) => step.onboarding_step_name === 'PAYCYCLE')) {
      this.selected_slide = this.slide_map.PAY_CYCLE;
    } else if (find(incomplete_onboarding_steps, (step) => step.onboarding_step_name === 'EMPLOYEE')) {
      this.selected_slide = this.slide_map.ADD_EMPLOYEES;
      this.productService.loadEmployees(this.existing_account, this.payhero_session)
        .then((employees) => {
          this.ph_employees = employees;
          // load existing pay cycle so we can put new employees on it
          this.productService.loadPayCycle(this.payhero_session)
            .then((pay_cycle) => {
              this.pay_cycle_key = pay_cycle.pay_cycle_key;
            });
        })
        .catch(() => { });
    }
  }

  validateAccountCreation(is_demo_account: boolean): boolean {
    return this.accountService.validateAccountCreation(
      is_demo_account,
      this.selected_product?.product_value || null,
      this.company_name,
      this.user_terms_agreed,
      this.contact_phone
    );
  }

  createNewAccount() {
    if (this.validateAccountCreation(false)) {
      this.loading = true;
      const additional_data: any = {};

      if (this.selected_product.product_value === 'PAYHERO') {
        if (!!this.ird_number) {
          this.validateIrdNumber();

          if (this.ird_number_valid) {
            additional_data.ird_number = this.ird_number;
          }
          else {
            this.loading = false;
            return;
          }
        }
        if (!!this.selected_region) {
          additional_data.region = this.selected_region;
        }
      }

      // Currently applies to both PH + Droppah
      if (!!this.number_of_employees) {
        additional_data.number_of_employees = this.number_of_employees;
      }
      else if (this.selected_product.product_value === 'KARMLY') {
        this.company_name = null; // clear so will default to name in service
      }

      if (this.selected_product.product_value === 'DROPPAH') {
        this.selected_industry_key = null;

        if (!!this.selected_business_type) {
          additional_data.business_type = this.selected_business_type;

          // If the business type is not 'Other' then set industry to Hospitality 
          if (this.selected_business_type !== 'Other') {
            const hospitality_key: number = parseInt(Object.keys(this.industry_map).find(key => this.industry_map[key] === 'Hospitality'));
            this.selected_industry_key = hospitality_key;
          }
        }

        if(!!this.selected_province) {
          additional_data.country_province_key = this.selected_province.country_province_key;
        }

      }

      this.accountService.createAccount(
        this.company_name,
        null,
        this.selected_product?.product_value || null,
        additional_data.number_of_employees,
        additional_data.region,
        this.selected_industry_key,
        additional_data.business_type,
        this.company_nzbn,
        this.company_industry_classification,
        additional_data.ird_number,
        false,
        this.selected_product.product_value !== 'PAYHERO', //don't redirect for payhero
        this.user_terms_agreed,
        true, // The SQL has logic for which products to do this for. This just lets it know we came from this page in particular
        this.contact_phone,
        additional_data.country_province_key
      )
        .then((res) => {
          // create a session for newly created payhero company
          if (this.selected_product?.product_value === 'PAYHERO') {
            this.productService.login_destination = 'PAYHERO_APP';

            this.productService.startNewSession(res.company_product_key, this.selected_product.product_value, false, true, null)
              .then((session) => {
                this.payhero_session = session;
                this.productService.setTitle('Create Account');

                //todo: ??
                // this.googleAnalyticsService.trackEvent('sign_up', product_value.toLowerCase(), this.userService.user_access_key);

                this.accountService.loadAccounts()
                  .then(() => {
                    this._initExistingAccount(res.company_product_key);
                    this.loading = false;
                  });
              })
              .catch((err) => this.modalService.errorModal(err.message));
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }

  validateAutoAccountCreation() {
    switch (this.selected_product?.product_value) {
      // At this stage auto creation only valid for Karmly
      case 'KARMLY':
        // Partners can't create Karmly accounts
        return !this.is_partner;
      default:
        return false;
    }
  }

  autoCreateNewAccount() {
    return new Promise<void>((resolve, reject) => {
      if (!!this.uiRouterGlobals.params.auto_account_create && this.validateAutoAccountCreation()) {
        this.loading = true;

        this.accountService.createAccount(
          null, // company name defaults to full name in service function
          null,
          this.selected_product?.product_value || null
        )
          .then(() => resolve())
          .catch(() => {
            this.loading = false;
            reject();
          });
      } else {
        reject();
      }
    });
  }

  createDemoAccount() {
    if (this.validateAccountCreation(true)) {
      this.accountService.createDemoAccount(
        this.selected_product.product_value,
        null,
        false
      )
        .finally(() => {
          this.loading = false;
        });
    }
  }

  validateIrdNumber() {
    this.ird_number_valid = CoreUtilService.validateIRDNumber(this.ird_number);
  }

  selectRegion(region: NZRegion) {
    this.selected_region = region;
  }

  selectNumberOfEmployees(option: string) {
    this.number_of_employees = option;
  }


  selectProduct(option: ProductValueAndName) {
    this.selected_product = option;
    this.show_demo_option = !this.accountService.activeSampleAccountsMap[this.selected_product.product_value];

    this.productService.current_product = this.selected_product.product_value;

    if (this.selected_product.product_value === 'DROPPAH') {
      this.selected_industry_key = null;
    } else {
      this.selected_business_type = null;
    }
  }

  selectIndustry(industry: string) {
    for (const key in this.industry_map) {
      if (industry === this.industry_map[key]) {
        this.selected_industry_key = parseInt(key);
        break;
      }
    }
  }

  selectBusinessType(business_type: string) {
    if (this.business_types.includes(business_type)) {
      this.selected_business_type = business_type;
    }
  }

  companyNZBNSearchUpdated(searchTerm: string) {
    this.company_name = searchTerm;

    // Search NZBN registry if above min length
    if (searchTerm.length >= this.nzbn_minimum_search_term_length) {
      this.company_nzbn_loading = true;
      this.accountService.searchNZBN(this.nzbn_access_token, searchTerm)
        .then(result => {
          this.companyNZBNSearch.updateSearchItems(result.search_term, result.items);
        })
        .finally(() => {
          this.company_nzbn_loading = false;
        });
    }
  }

  setNZBN(updatedNZBN: string) {
    this.company_nzbn = updatedNZBN;
  }

  nzbnItemSelected(item: any, fillName: boolean, fillNZBN: boolean, fillIndustry: boolean) {

    // Company name update. 'No trading name' seems to be a default tradingName value returned when there isn't one set
    if (fillName && item.tradingNames && item.tradingNames.length && item.tradingNames[0].name !== 'No trading name') {
      this.company_name = item.tradingNames[0].name;
      this.companyNZBNSearch.searchTerm = item.tradingNames[0].name;
      this.companyNZBNSearch.searchTermInputUpdated(item.tradingNames[0].name, true);
    }
    else if (fillName && item.entityName) {
      this.company_name = item.entityName;
      this.companyNZBNSearch.searchTerm = item.entityName;
      this.companyNZBNSearch.searchTermInputUpdated(item.entityName, true);
    }

    // NZBN number update
    if (fillNZBN && item.nzbn) {
      this.company_nzbn = item.nzbn;
    }

    // Industry update
    if (fillIndustry && !this.selected_industry_key) {
      this.accountService.getNZBNCompany(this.nzbn_access_token, item.nzbn)
        .then(result => {

          if (result.industryClassifications.length) {
            const code = result.industryClassifications[0].classificationCode;
            const class_code = code.substring(0, 5);
            const industry_key = this.anzsic_class_map[class_code];

            this.selected_industry_key = industry_key;
            this.company_industry_classification = code;
          }
        });
    }
  }




  /////////////////////////////////////////////
  ///   new stuff

  selectPayCycle(cycle: PayCycleInputs) {
    this.pay_period_start = null;
    this.pay_date = null;
    this.selected_pay_day = null;
    this.next_pay_day = null;
    this.selected_pay_cycle = cycle;
  }

  toggleDayOfWeek() {

    if (!!this.pay_date) {
      if (this.ordinal_day_of_week) {
        this.next_pay_day = this.pay_date;
      } else {
        this.selected_pay_day = this.week_labels[this.pay_date.getDay() - 1];
      }

    }

    this.pay_date = null;
    this.ordinal_day_of_week = !this.ordinal_day_of_week;
    this.payPeriodChanged();
  }

  nextSlide() {
    this.continue_pressed = true;
    if (this._validInputs()) {
      switch (this.selected_slide) {
        case this.slide_map.COMPANY_INFO:
          this.createNewAccount();
          break;
        case this.slide_map.PAY_CYCLE:
          this.savePayCycle();
          break;
        case this.slide_map.ADD_EMPLOYEES:
          this.inviteEmployees();
          break;
        default: // COMPLETION
      }

      if (this.selected_slide !== this.slides[this.slides.length - 1]) {
        this.selected_slide = this.slides[this.slides.indexOf(this.selected_slide) + 1];
      }

      this.continue_pressed = false;
    }
  }

  previousSlide() {
    if (this.selected_slide !== this.slides[0]) {
      this.selected_slide = this.slides[this.slides.indexOf(this.selected_slide) - 1];
    }
  }

  payheroRedirectToProduct(selected_action: PayHeroActionButton = null) {
    const login_source: DestinationValue = 'PAYHERO_APP';
    const open_in_new_tab = false; //temporary
    const external_state_name: string = selected_action?.redirect_to_product_page || null;

    this.accountService.updateOnboardingStep(this.existing_account, 'ACTION', false, null, false, selected_action?.value || 'DASHBOARD')
      .finally(() => {
        this.redirectService.redirectToProduct(login_source, open_in_new_tab, external_state_name);
      });
  }

  openEmployeeInfoModal(employee: PhEmployee = null) {
    // Info modal only if first employee added
    if ((!employee || !employee.employee_key) && !this.ph_employees?.length) {
      this.modalService.phEmployeeInfoModal()
        .then(() => this.openEmployeeModal(employee))
        .catch(() => { });
    } else {
      this.openEmployeeModal(employee);
    }
  }

  openEmployeeModal(employee: PhEmployee = null) {
    // prompt user to create themselves as first employee
    if (!employee && !this.ph_employees.length) {
      const user = this.userService.user_access;

      employee = new PhEmployee(
        null,
        null,
        user.first_name,
        user.full_name ? user.full_name.split(' ')[1] : null,
        user.registered_email
      );
    }

    // load the full employee record so we can edit it
    // resolve immediately if making a new employee
    this.loadExistingEmployee(employee)
      .then((full_employee) => {
        let employee_for_modal;
        if (!!full_employee) employee_for_modal = full_employee;
        else employee_for_modal = employee;

        this.modalService.phEmployeeModal(employee_for_modal)
          .then((updated_employee) => {
            if (!!updated_employee) {
              // automatically add employee to pay cycle
              updated_employee.pay_cycle_key = this.pay_cycle_key;

              this.productService.saveEmployee(updated_employee, this.payhero_session)
                .then((employee) => {
                  this.accountService.updateOnboardingStep(this.existing_account, 'EMPLOYEE', true, employee.employee_key, !updated_employee.invite_to_portal_flag)
                    .then(() => {
                      this.productService.loadEmployees(this.existing_account, this.payhero_session)
                        .then((employees) => this.ph_employees = employees);
                    });
                })
                .catch((err) => this.modalService.errorModal(err.message));
            }
          })
          .catch(() => { });
      })
      .catch(() => { });
  }

  loadExistingEmployee(employee: PhEmployee) {
    return new Promise<PhEmployee>((resolve, reject) => {
      if (!employee || !employee.employee_key) resolve(null);
      else {
        this.productService.loadEmployee(employee.employee_key, employee.invite_to_portal_flag, this.payhero_session)
          .then((full_employee) => resolve(full_employee))
          .catch(() => { });
      }
    });
  }

  openImportModal() {
    this.modalService.phImportModal()
      .catch(() => { });
  }

  payPeriodChanged() {

    if (this.selected_pay_cycle.value === 'MONTHLY') {
      if (!this.pay_period_start || !this.next_pay_day) return;

      this.pay_period_end = cloneDeep(this.pay_period_start);
      this.pay_period_end.setMonth(this.pay_period_end.getMonth() + 1);
      this.pay_period_end.setDate(this.pay_period_end.getDate() - 1);

      if (this.ordinal_day_of_week) {
        this.selected_pay_day = this.dateToTimePipe.transform(this.next_pay_day, 'dddd');

        this.pay_date = cloneDeep(this.pay_period_end);
        this.pay_date.setDate(this.pay_date.getDate() + 1);

        while (this.pay_date.getDay() !== TimeUtilService.getWeekDayValue(this.selected_pay_day.slice(0, 3))) {
          this.pay_date.setDate(this.pay_date.getDate() + 1);
        }
        this.calculateFuturePayDayString();

      } else {
        this.pay_date = this.next_pay_day;
      }

    } else {
      if (!this.pay_period_start || !this.selected_pay_day) return;

      this.pay_period_end = cloneDeep(this.pay_period_start);
      this.pay_period_end.setDate(this.pay_period_end.getDate() + 6);

      if (this.selected_pay_cycle.value === 'FORTNIGHTLY') {
        this.pay_period_end.setDate(this.pay_period_end.getDate() + 7);
      }

      if (this.selected_pay_cycle.value === 'FOUR_WEEKLY') {
        this.pay_period_end.setDate(this.pay_period_end.getDate() + 21);
      }

      this.pay_date = cloneDeep(this.pay_period_end);
      this.pay_date.setDate(this.pay_date.getDate() + 1);

      while (this.pay_date.getDay() !== TimeUtilService.getWeekDayValue(this.selected_pay_day.slice(0, 3))) {
        this.pay_date.setDate(this.pay_date.getDate() + 1);
      }

    }

  }



  selectedPayDayChanged(selectedDay: any) {
    this.selected_pay_day = selectedDay;
    this.payPeriodChanged();
  }

  selectProvince(province: CountryProvince) {
    this.selected_province = province;
    this.selected_province_name = province.province_name;
  }


  savePayCycle() {
    const pay_cycle = {
      pay_cycle_name: this.selected_pay_cycle.label + ' Pay Cycle',
      pay_frequency: this.selected_pay_cycle.label,
      pay_date: this.pay_date,
      period_start_date: this.pay_period_start,
      same_day_of_month: this.ordinal_day_of_week
    };

    this.productService.savePayCycle(pay_cycle, this.payhero_session)
      .then((res) => {
        // save pay cycle key in additional_data
        this.accountService.updateOnboardingStep(this.existing_account, 'PAYCYCLE', false, null);
        this.pay_cycle_key = res.pay_cycle_key;
      })
      .catch(() => { });
  }

  getInviteEmployees() {
    const employees = [];
    for (const employee of this.ph_employees) {
      if (employee.invite_to_portal_flag) {
        employees.push({
          email_address: employee.email,
          full_name: employee.employee_code,
          role: 'EMPLOYEE',
          product_params: JSON.stringify({ employee_key: employee.employee_key })
        });
      }
    }
    return employees;
  }

  getSendInvitesString() {
    const invite_num = this.getInviteEmployees().length;
    if (invite_num === 0) {
      return 'Continue';
    } else if (invite_num === 1) {
      return 'Send 1 Invite & Continue';
    }
    return 'Send ' + invite_num + ' Invites & Continue';
  }

  inviteEmployees() {
    const employees = this.getInviteEmployees();

    this.productService.inviteEmployee(employees, this.payhero_session)
      .then(() => { })
      .catch(() => { })
      .finally(() => {
        this.accountService.updateOnboardingStep(this.existing_account, 'EMPLOYEE');
        // Complete - clear this key if its been cached
        this.stateDataService.clearCachedComponentSessionData(
          'AccountCreateComponent',
          'existingCompanyProductKey',
        );
      });
  }

  logout() {
    this.authService.logout();
  }

  calculateFuturePayDayString(force: boolean = false) {
    //week number in month
    const week_index: number = Math.ceil(TimeUtilService.getWeekDayValueInMonth(this.pay_date));

    const index_string = CoreUtilService.getOrdinalNumberStringsToFive()[week_index - 1];

    this.selected_day_display = index_string + ' ' + this.dateToTimePipe.transform(this.pay_date, 'dddd');
  }
}
