import { Subscription, Subject, Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { Account } from '../../models/account/account';
import { DpAccount } from '../../models/account/dp-account';
import { PhAccount } from '../../models/account/ph-account';
import { InvAccount } from '../../models/account/inv-account';
import { KmAccount } from '../../models/account/km-account';

import { DbUtilService } from '../db-util/db-util.service';
import { ProductService } from '../product/product.service';
import { RedirectService } from '../redirect/redirect.service';
import { SortUtilService } from './../sort-util/sort-util.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { FtAccountService } from '../ft-account/ft-account.service';
import { ProductName, ProductValue, PaymentMethod, AccountFilter, AccountDomainData, ProductValueAndName } from './../../app.types';
import { ClientService } from 'src/app/services/client/client.service';
import { DomService } from 'src/app/services/dom/dom.service';
import { UserService } from 'src/app/services/user/user.service';
import { SocketService } from '../socket/socket.service';
import { Client } from 'src/app/models/client/client';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';

import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  readonly region_to_holiday = {
    'Northland': 'Auckland Anniversary',
    'Auckland': 'Auckland Anniversary',
    'Waikato': 'Auckland Anniversary',
    'Bay of Plenty': 'Auckland Anniversary',
    'Gisborne': 'Auckland Anniversary',
    'Hawke\'s Bay': 'Hawke\'s Bay Anniversary',
    'Taranaki': 'Taranaki Anniversary',
    'Manawatu - Wanganui': 'Wellington Anniversary',
    'Wellington': 'Wellington Anniversary',
    'Tasman': 'Nelson Anniversary',
    'Nelson': 'Nelson Anniversary',
    'Marlborough': 'Marlborough Anniversary',
    'West Coast': 'Westland Anniversary',
    'Canterbury': 'Canterbury Anniversary',
    'Otago': 'Otago Anniversary',
    'Southland': 'Southland Anniversary',
    'Chatham Islands': 'Chatham Islands Anniversary'
  };

  readonly partner_karmly_warning_message: string =
    'Karmly is a free tool for independent contractors and isn\'t currently compatible with FlexiTime partner accounts. To try Karmly, you can <a target="_blank" href="https://www.karmly.io/signup?utm_source=app&utm_medium=create_account">sign up here</a> with a different email address.';

  private _activeSampleAccountsMap: Partial<Record<ProductValue, Account>>;

  private _event_subscriptions: Subscription[] = [];
  private _subscriptionServiceListenerPaused: boolean = false;
  private _serviceDataUpdateEvent = new Subject<void>();

  private _accountUpdateEvent = new Subject<any>();

  private _marketing_additional_data: any = null;

  accounts: Account[] = [];
  accountsMap: Record<number, Account> = {};

  droppah_mobile_accounts: DpAccount[] = [];

  clientAccountsMap: Record<number, Account[]> = {};

  industryMap: Record<number, string>;
  businessTypes: string[] = [];
  anzsicClassMap: Record<string, number> = {};

  serviceSetup: boolean = false;

  droppah_top_industries: string[] = ['Hospitality', 'Retail', 'Manufacturing', 'Health Care and Social Assistance'];


  constructor(
    public dbUtilService: DbUtilService,
    public productService: ProductService,
    public redirectService: RedirectService,
    public subscriptionService: SubscriptionService,
    public clientService: ClientService,
    public domService: DomService,
    public userService: UserService,
    public ftAccountService: FtAccountService,
    public socketService: SocketService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  // Initialisation ///////////////

  initialiseService() {
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve(this.accounts);
      }
      else {
        this.initEventListeners();
        this.loadAccounts()
          .then(() => {
            this.loadIndustryList()
              .finally(() => {
                this.serviceSetup = true;
                resolve(this.accounts);
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.accounts = [];
    this.accountsMap = {};
    this.clientAccountsMap = {};
    this.clearEventListeners();
    this.serviceSetup = false;
  }

  initEventListeners() {
    // Reload Subscription (For sub accounts list) and then accounts list
    this.socketService.subToEvent('flexitime_account_updated', 'AccountService', (event) => {
      this.subscriptionService.loadSubscription().finally(() => {
        this.loadAccounts().finally(() => {
          this._accountUpdateEvent.next(event);
        });
      });
    });

    this._event_subscriptions.push(
      this.subscriptionService.getServiceDataUpdateEvent().subscribe(() => {
        this.refreshAccountSubscriptions();
      })
    );
  }

  clearEventListeners() {
    this._event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._event_subscriptions = [];

    this._subscriptionServiceListenerPaused = false;
  }

  // Getters //////////////////////

  get activeSampleAccountsMap(): Partial<Record<ProductValue, Account>> {
    return _.cloneDeep(this._activeSampleAccountsMap);
  }

  get marketing_additional_data() {
    return this._marketing_additional_data;
  }
  set marketing_additional_data(data: string) {
    this._marketing_additional_data = data === 'null' ? null : data;
  }

  // Has login access to droppah mobile either via an active account or candidate login
  hasDroppahMobileAccess() {
    if (this.userService.is_droppah_candidate) {
      return true;
    }
    let has_active_droppah_mobile_access = false;

    for (const account of this.droppah_mobile_accounts) {
      if (account.allow_user_access) {
        has_active_droppah_mobile_access = true;
      }
    }
    return has_active_droppah_mobile_access;
  }

  getServiceDataUpdateEvent(): Observable<any> {
    return this._serviceDataUpdateEvent.asObservable();
  }

  getAccountUpdatedEvent(): Observable<any> {
    return this._accountUpdateEvent.asObservable();
  }

  userHasAllSampleAccountsActive(): boolean {
    return !!this._activeSampleAccountsMap.DROPPAH && !!this._activeSampleAccountsMap.PAYHERO;
  }

  getIndustryMap(): Record<number, string> {
    return _.cloneDeep(this.industryMap);
  }

  getBusinessTypes(): string[] {  
    return _.cloneDeep(this.businessTypes);
  }

  getDroppahTopIndustries(): string[] {
    return this.droppah_top_industries;
  }

  getANZSICClassMap(): Record<string, number> {
    return _.cloneDeep(this.anzsicClassMap);
  }

  getAutoLoginAccountForSplashScreen(
    auto_login_company_product_key: number = null,
    auto_login_partner_flag: boolean = false
  ): Account {
    let auto_select_account: Account = null;

    if (auto_login_partner_flag || !this.userService.is_partner) {
      const filters: AccountFilter[] = [
        'EXCLUDE_CANCELLED',
        'EXCLUDE_NO_USER_ACCESS',
        'EXCLUDE_DEMO',
        'EXCLUDE_DROPPAH_MOBILE_ACCOUNTS'
      ];
      const all_accounts = this.getAllAccounts(filters, null, null, null, true);

      if (
        all_accounts.length === 0 &&
        this.hasDroppahMobileAccess()
      ) {
        auto_select_account = this.getUnifiedDroppahMobileAccount();
      }
      // Try to use the provided company product key
      else if (!!auto_login_company_product_key) {
        auto_select_account = this.getAccount(auto_login_company_product_key, true);
        // Seeing as we're using the getAccount function here we're not applying the filters we use for the other checks above and below for auto-logging in
        // This means we need a specific check to ensure we're not logging them in to an invalid acc. Allow User Access should cover our bases for this
        if (!auto_select_account?.allow_user_access) auto_select_account = null;
      }

      // If neither above found an account, try limit by product
      // Came from login.payhero.co.nz or login.droppah.com or login.invoxy.com or login.karmly.io
      if (!auto_select_account && this.productService.current_product !== 'FLEXITIME') {
        const product_accounts = this.getAllAccounts(
          filters, null, [this.productService.current_product], null, true
        );

        // Only has one account for the currently selected product
        if (product_accounts.length === 1) {
          auto_select_account = product_accounts[0];
        }
        else if (
          product_accounts.length === 0 &&
          this.hasDroppahMobileAccess()
        ) {
          auto_select_account = this.getUnifiedDroppahMobileAccount();
        }
      }
    }
    return auto_select_account;
  }

  getAvailableProductsForAccountCreation(
    creating_partner_account: boolean = false
  ): ProductValueAndName[] {
    const products_to_exclude = new Set<ProductValue>();

    if (this.userService.is_partner) {
      if (creating_partner_account) {
        const partner_client_key = this.clientService.partnerClient?.client_key || null;

        if (!!partner_client_key) {
          // Partner clients can only have one associated account for each product
          const partner_accounts = this.getAllAccounts(
            ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO'],
            partner_client_key, null, null, true
          );

          for (const account of partner_accounts) {
            products_to_exclude.add(account.product_value);
          }
        }
      }
    }

    const products: ProductValueAndName[] = [];

    if (!products_to_exclude.has('PAYHERO')) {
      products.push({ product_value: 'PAYHERO', product_name: 'PayHero' });
    }
    if (!products_to_exclude.has('DROPPAH')) {
      products.push({ product_value: 'DROPPAH', product_name: 'Droppah' });
    }
    if (!products_to_exclude.has('INVOXY')) {
      products.push({ product_value: 'INVOXY', product_name: 'Karmly' });
    }
    // if (!products_to_exclude.has('KARMLY')) {
    //   products.push({ product_value: 'KARMLY', product_name: 'Karmly' });
    // }

    return products;
  }

  getAccount(
    company_product_key: number,
    get_reference: boolean = false
  ): Account {
    const account = this.accountsMap[company_product_key] || null;
    return get_reference ? account : _.cloneDeep(account);
  }

  getDroppahMobileAccounts(): DpAccount[] {
    return _.clone(this.droppah_mobile_accounts);
  }

  getAllAccounts(
    filters: AccountFilter[] = [],
    client_key: number = null,
    products_to_include: ProductValue[] = null,
    products_to_exclude: ProductValue[] = null,
    get_reference: boolean = false
  ): Account[] {
    const account_filters = new Set(filters || []);
    const filtered_accounts = [];

    products_to_include = products_to_include ? products_to_include.filter((value) => this.productService.productIsValid(value)) : [];
    const products_include_map = new Set(products_to_include);

    products_to_exclude = products_to_exclude ? products_to_exclude.filter((value) => this.productService.productIsValid(value)) : [];
    const products_exclude_map = new Set(products_to_exclude);

    const all_accounts = !client_key ? this.accounts : (this.clientAccountsMap[client_key] || []);
    const partner_client_key = this.clientService.partnerClient?.client_key || null;

    for (const account of all_accounts) {
      if (
        (account_filters.has('EXCLUDE_CANCELLED') && account.cancelled_flag) ||
        (account_filters.has('EXCLUDE_NO_SUBSCRIPTION') && !account.account_subscription) ||
        (account_filters.has('EXCLUDE_NO_USER_ACCESS') && !account.allow_user_access) ||
        (account_filters.has('EXCLUDE_NO_SUBSCRIPTION_ACCESS') && account.no_subscription_access_flag) ||
        (account_filters.has('EXCLUDE_DEMO') && !!account.sample_company) ||
        (account_filters.has('DEMO_ONLY') && !account.sample_company) ||
        (account_filters.has('ADMIN_ONLY') && !account.admin_flag) || // This will include owners
        (account_filters.has('UNLINKED_ONLY') && !!account.client_key) ||
        //sub_get_company_product has_company_setting_access returns true if admin or team manager which is the filtering of droppah mobile accounts
        //if db return changes this will also need to change
        (account_filters.has('EXCLUDE_DROPPAH_MOBILE_ACCOUNTS') && account.product_value === 'DROPPAH' && !account.has_company_settings_access) ||
        (
          this.userService.is_partner &&
          account_filters.has('EXCLUDE_PARTNER_ACCOUNTS') &&
          partner_client_key !== null &&
          account.client_key === partner_client_key
        )
      ) {
        continue;
      }
      else if (
        products_include_map.size > 0 && !products_include_map.has(account.product_value)
      ) {
        continue;
      }
      else if (
        products_exclude_map.size === 0 || !products_exclude_map.has(account.product_value)
      ) {
        filtered_accounts.push(account);
      }
    }
    return get_reference ? filtered_accounts : _.cloneDeep(filtered_accounts);
  }

  // Network Calls ///////////////

  loadIndustryList(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIGet('nzbn/list')
        .then((data) => {
          this.industryMap = {};
          this.anzsicClassMap = {};

          for (const industry of data.industry_list) {
            this.industryMap[industry.industry_key] = industry.industry_name;
          }

          this.businessTypes = [
            'Café',
            'Restaurant',
            'Bar',
            'Club',
            'Hotel',
            'Catering',
            'Takeaway',
            'Pub/Tavern',
            'Bakery/Patisserie',
            'Food Truck',
            'Other'
          ];

          for (const anzsic_class of data.anzsic_classes) {
            this.anzsicClassMap[anzsic_class.anzsic_code] = anzsic_class.industry_key;
          }

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private _excludeDroppahMobileAccounts(accounts: Account[]): Account[] {
    return accounts.filter(account => {
      return account.product_name !== 'Droppah' || account.admin_flag === true;
    });
  }

  private _getDroppahMobileAccounts(accounts: Account[]): DpAccount[] {
    return (accounts as DpAccount[]).filter(account => {
      //sub_get_company_product has_company_setting_access returns true if admin or team manager which is the filtering of droppah mobile accounts
      //if db return changes this will also need to change
      return account.product_name === 'Droppah' && account.has_company_settings_access === false;
    });
  }

  loadAccounts(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIGet('company_product')
        .then((data) => {
          const accounts = this.setupAccounts(data);

          this.accounts = accounts;
          this.droppah_mobile_accounts = this._getDroppahMobileAccounts(accounts);

          this.accountsMap = {};

          for (const account of this.accounts) {
            this.accountsMap[account.company_product_key] = account;
          }

          this._reloadClientAccountMap();
          this._refreshActiveSampleAccountMap();

          SortUtilService.sortList(this.accounts, 'company_name', 'product_name');
          this._serviceDataUpdateEvent.next();

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Reload single account
  reloadAccount(company_product_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIGet('company_product', { company_product_key }, true)
        .then((res) => {
          const account = this.setupAccount(res[0]);

          // Shouldn't happen,. but if it does, don't attempt to replace the account in the accounts list + map
          if (account === null) return reject();

          for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].company_product_key === account.company_product_key) {
              this.accounts[i] = account;
            }
          }
          this.accountsMap[account.company_product_key] = account;

          this._reloadClientAccountMap();
          this._refreshActiveSampleAccountMap();

          SortUtilService.sortList(this.accounts, 'company_name', 'product_name');
          this._serviceDataUpdateEvent.next();

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  reloadClient(client_key: number): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!!client_key) {
        this.clientService.reloadClient(client_key)
          .finally(() => {
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  createAccount(
    company_name: string = null,
    client_key: number = null,
    product_value: ProductValue = null,
    number_of_employees: string = null,
    region: string = null,
    industry_key: number = null,
    business_type: string = null,
    nzbn: string = null,
    industry_classification: string = null,
    ird_number: string = null,
    open_company_in_new_tab: boolean = false,
    redirect_to_account: boolean = true,
    sub_agreement_flag: boolean = false,
    initialise_onboarding_flag: boolean = false,
    contact_phone: string = null,
    country_province_key: string = null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.validateAccountCreation(
        false, product_value, company_name, true, contact_phone
      )) {
        const data: any = {
          // fall back on full name if no company name (e.g. for Karmly)
          company_name: company_name || this.userService.userAccess.full_name,
          product_name: product_value || this.productService.current_product,
          marketing_additional_data: this.marketing_additional_data,
          initialise_onboarding_flag
        };

        if (client_key) {
          data.client_key = client_key;
        }
        if (sub_agreement_flag || number_of_employees !== null || region !== null || ird_number !== null) {
          const additional_data: any = {
            sub_agreement_flag
          };

          if (number_of_employees !== null) {
            additional_data.number_of_employees = number_of_employees;
          }
          if (region !== null) {
            additional_data.region = region;
            additional_data.anniversary_name = this.region_to_holiday[region];
          }
          if (ird_number !== null) {
            additional_data.ird_number = ird_number;
          }

          if (contact_phone !== null) {
            additional_data.contact_phone = contact_phone;
          }

          if (business_type !== null) {
            additional_data.business_type = business_type;
          }

          if (country_province_key !== null) {
            additional_data.country_province_key = country_province_key;
          }

          data.additional_data = JSON.stringify(additional_data);
        }

        this.dbUtilService.APIPost('company_product/create', data)
          .then((res) => {

            // Asynchronously set industry information using the nzbn api if applicable
            if (industry_key || nzbn) {
              const industry_data = {
                company_product_key: res.company_product_key,
                industry_key: industry_key,
                nzbn: nzbn,
                industry_classification: industry_classification
              };

              this.dbUtilService.APIPost('nzbn', industry_data);
            }

            this.googleAnalyticsService.trackEvent('sign_up', product_value.toLowerCase(), this.userService.user_access_key, res.company_product_key);

            if (redirect_to_account) {
              this.productService.startNewSession(res.company_product_key, product_value)
                .then((session) => {
                  this.redirectService.redirectToProduct(session.login_source, open_company_in_new_tab);
                })
                .catch(() => {
                  this._reloadDataAfterAccountCreation(client_key)
                    .finally(() => {
                      resolve(null);
                    });
                });
            }
            if (!redirect_to_account || (redirect_to_account && open_company_in_new_tab)) {
              // Don't reload account data ONLY when we've come from the onboarding page
              // We instead need to return the account we've just created
              if (product_value === 'PAYHERO' && !!initialise_onboarding_flag) {
                resolve(res);
              } else {
                this._reloadDataAfterAccountCreation(client_key)
                  .finally(() => {
                    resolve(null);
                  });
              }
            }
          })
          .catch((err) => {
            reject(err.message);
          });
      }
      else {
        reject();
      }
    });
  }

  private _reloadDataAfterAccountCreation(client_key: number = null): Promise<void> {
    return new Promise<void>((resolve) => {
      // Pause subscription service listener so loadAccounts isn't called twice
      this._subscriptionServiceListenerPaused = true;

      this.subscriptionService.loadSubscription()
        .finally(() => {
          const promises = [this.loadAccounts()];
          if (client_key !== null) {
            promises.push(this.reloadClient(client_key));
          }

          forkJoin(promises)
            .toPromise()
            .finally(() => {
              this._subscriptionServiceListenerPaused = false;
              resolve();
            });
        });
    });
  }

  addOrRemoveAccountFromClient(
    client: Client, account: Account, toDelete: boolean = false
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = {
        company_product_key: account.company_product_key,
        client_key: client.client_key,
        deleted_flag: toDelete
      };

      this.dbUtilService.APIPost('client/companyproduct', data)
        .then((res) => {
          if (res.access_error_flag) {
            this.domService.showBanner({
              message: 'There was an issue modifying this User\'s access to a Client\'s Account',
              type: 'ERROR'
            });
          }

          this.subscriptionService.loadSubscription()
            .finally(() => {
              forkJoin([
                this.reloadAccount(account.company_product_key),
                this.reloadClient(client.client_key)
              ])
                .toPromise()
                .finally(() => {
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  createDemoAccount(
    product_value: ProductValue = null,
    company_name: string = null,
    new_tab: boolean = true
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.validateAccountCreation(
        true, product_value, company_name, true
      )) {
        if (!product_value) {
          product_value = this.productService.current_product;
        }

        if (!company_name) {
          company_name = 'Demo Account';
        }

        if (product_value === 'FLEXITIME') {
          return reject('A valid poduct is required to create a demo account.');
        }

        const data: any = {
          product_name: product_value,
          company_name
        };

        this.dbUtilService.APIPost('company_product/create/demo', data)
          .then((res) => {
            this.productService.startNewSession(res.company_product_key, product_value)
              .then((session) => {
                this.redirectService.redirectToProduct(session.login_source, new_tab);
              });

            this.loadAccounts()
              .then(() => {
                resolve(res.company_product_key);
              });
          })
          .catch((err) => {
            reject(err.message);
          });
      }
      else {
        reject();
      }
    });
  }

  deleteDemoAccount(
    company_product_key: Number = null
  ) {
    return new Promise<void>((resolve, reject) => {

      if (!company_product_key) {
        return;
      }

      const data: any = {
        company_product_key
      };

      this.dbUtilService.APIPost('company_product/delete/demo', data)
        .then(() => {
          this.loadAccounts()
            .then(() => {
              resolve();
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  subscribeAccount(company_product_key: number, payment_method: PaymentMethod,
    subscription_plan_key: number, promo_code: string = null,
    reload_product_details_flag: boolean = false) {
    return new Promise<void>((resolve, reject) => {

      const data = {
        company_product_key,
        payment_method,
        subscription_plan_key,
        promo_code,
        reload_product_details_flag
      };

      this.dbUtilService.APIPost('subscription/subscribe', data)
        .then(() => {
          // Pause subscription service listener so loadAccounts isn't called twice
          this._subscriptionServiceListenerPaused = true;

          this.subscriptionService.loadSubscription()
            .finally(() => {
              this.loadAccounts()
                .finally(() => {
                  this._subscriptionServiceListenerPaused = false;
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  cancelAccountSubscription(company_product_key: number, product_name: string = null) {
    return new Promise<void>((resolve, reject) => {

      const data = {
        company_product_key,
        product_name
      };

      this.dbUtilService.APIPost('subscription/remove/company', data)
        .then(() => {
          // Pause subscription service listener so loadAccounts isn't called twice
          this._subscriptionServiceListenerPaused = true;

          this.subscriptionService.loadSubscription()
            // Load Accounts and FT accounts after cancelling.
            .finally(() => {
              forkJoin([
                this.loadAccounts(),
                this.ftAccountService.loadFtAccounts()
              ])
                .toPromise()
                .finally(() => {
                  this._subscriptionServiceListenerPaused = false;
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  transferAccountSubscription(
    account: Account,
    transfer_contact_name: string,
    transfer_email_address: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = {
        company_product_key: account.company_product_key,
        company_name: account.company_name,
        transfer_contact_name,
        transfer_email_address
      };

      this.dbUtilService.APIPost('subscription/transfer', data)
        .then(() => {
          // Pause subscription service listener so loadAccounts isn't called
          this._subscriptionServiceListenerPaused = true;

          this.subscriptionService.loadSubscription()
            .finally(() => {
              this.reloadAccount(account.company_product_key)
                .finally(() => {
                  this._subscriptionServiceListenerPaused = false;
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  revokeAccountSubscriptionTransfer(account: Account): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = {
        company_product_key: account.company_product_key
      };

      this.dbUtilService.APIPost('subscription/transfer/revoke', data)
        .then(() => {
          // Pause subscription service listener so loadAccounts isn't called
          this._subscriptionServiceListenerPaused = true;

          this.subscriptionService.loadSubscription()
            .finally(() => {

              this.loadAccounts()
                .finally(() => {
                  this._subscriptionServiceListenerPaused = false;
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  updateOnboardingStep(account: Account, onboarding_step: string, incomplete_flag: boolean = false, employee_key: number = null, remove_invite_flag: boolean = false, selected_action: string = null) {
    return new Promise<void>((resolve, reject) => {

      // save created, but not yet invited employee in onboarding step additional data
      let additional_data = [];
      if (onboarding_step === 'EMPLOYEE') {
        // we dont want to complete employee step until invites are sent
        if (!!employee_key) incomplete_flag = true;

        // add new employee key to existing additional data if any
        const employee_step = _.find(account.incomplete_onboarding_steps, (step) => step.onboarding_step_name === 'EMPLOYEE');
        additional_data = !!employee_step?.additional_data ? employee_step.additional_data : [];

        const employee_index = _.findIndex(additional_data, (employee) => employee.employee_key === employee_key);
        // if we have an employee
        if (!!employee_key) {
          // if they're not already in additional data, and we're not wanting to remove
          if (employee_index < 0 && !remove_invite_flag) {
            // add employee
            additional_data.push({ employee_key });
          }
          // otherwise if they ARE in additional data, and we want to remove them
          else if (employee_index > -1 && !!remove_invite_flag) {
            // remove employee
            additional_data.splice(employee_index, 1);
          }
        }
      }
      else if (onboarding_step === 'ACTION') {
        additional_data.push({ action_taken: selected_action });
      }

      const data = {
        company_product_key: account.company_product_key,
        onboarding_step,
        completed_flag: !incomplete_flag,
        additional_data
      };

      this.dbUtilService.APIPost('company_product/onboarding', data)
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  getInvoxyAccountLogo(company_domain: string): Promise<AccountDomainData> {
    return new Promise<AccountDomainData>((resolve, reject) => {

      this.dbUtilService.APIGetInvoxy('company/logo', { company_domain }, true)
        .then((res) => {
          resolve(res[0]);
        })
        .catch(() => reject());
    });
  }

  getNZBNAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.dbUtilService.APIGet('nzbn/token', null, true)
        .then((res) => {
          resolve(res.access_token);
        })
        .catch(() => reject);
    });
  }

  getNZBNCompany(access_token: string, nzbn: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIGetNZBN('nzbn/v5/entities/' + nzbn, access_token, null, true)
        .then((result) => {
          resolve(result);
        })
        .catch(() => reject);
    });
  }

  searchNZBN(access_token: string, search_term: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!access_token || !search_term || search_term.length < 3) return resolve({});

      const params = {
        'search-term': search_term,
        'entity-status': 'Registered,VoluntaryAdministration,InReceivership,InLiquidation,InStatutoryAdministration,Inactive'
      };

      this.dbUtilService.APIGetNZBN('nzbn/v5/entities', access_token, params, true)
        .then((result) => {
          resolve({
            search_term: search_term,
            items: result.items
          });
        })
        .catch(() => reject);
    });
  }

  refreshAccountSubscriptions() {
    for (const account of this.accounts) {
      account.account_subscription = this.subscriptionService.getAccountSubscriptionForAccount(
        account.company_product_key
      );
      account.updateAccountActions();
    }

    this._serviceDataUpdateEvent.next();
  }

  private _refreshActiveSampleAccountMap() {
    this._activeSampleAccountsMap = {
      PAYHERO: null,
      DROPPAH: null,
      INVOXY: null,
      KARMLY: null
    };

    for (const account of this.accounts) {
      if (account.sample_company && account.owner_flag) {
        this._activeSampleAccountsMap[account.product_value] = account;
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////

  setupAccounts(data: any[]): Account[] {
    const accounts = [];

    for (const d of data) {
      const account = this.setupAccount(d);

      if (account !== null) {
        accounts.push(account);
      }
    }

    return accounts;
  }

  getUnifiedDroppahMobileAccount() {
    if (this.hasDroppahMobileAccess()) {
      return new DpAccount(
        false,
        false,
        true,
        true,
        true,
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        1,
        'Droppah',
        false,
        null,
        null,
        null,
        false,
        false,
        null,
        false,
        true,
        null,
        null,
        false,
        1,
        null
      );
    }
    else {
      return null;
    }
  }

  setupAccount(c: any): Account {
    const account_subscription = this.subscriptionService.getAccountSubscriptionForAccount(
      c.company_product_key
    );

    switch (c.product_name) {
      case 'Droppah':
        return new DpAccount(
          c.access_deactivated,
          c.admin_flag,
          c.allow_user_access,
          c.allow_external_connect,
          c.allow_user_access || c.suspended_flag, // allow_account_select - suspended accounts can be selected to pop invoice modal
          c.cancelled_flag,
          c.client_key,
          c.company_logo,
          c.company_name,
          c.company_product_key,
          c.external_company_reference,
          c.last_session_date ? new Date(c.last_session_date) : null,
          c.owner_flag,
          c.pending_owner_flag,
          c.pending_subscription_key,
          c.pending_subscription_contact_name,
          c.pending_subscription_contact_email,
          c.product_key,
          c.product_name,
          c.sample_company,
          c.subscription_key,
          c.subscription_billing_name,
          c.subscription_billing_email,
          c.no_subscription_access_flag,
          c.suspended_flag,
          c.trial_days_remaining,
          c.trial_expired_flag,
          c.subscribed_flag,
          c.user_access_key,
          account_subscription,
          c.has_company_settings_access,
          c.number_of_people,
          c.auto_promo_code
        );
      case 'PayHero':
        return new PhAccount(
          c.access_deactivated,
          c.admin_flag,
          c.allow_user_access,
          c.allow_external_connect,
          c.allow_user_access || c.suspended_flag, // allow_account_select - suspended accounts can be selected to pop invoice modal
          c.cancelled_flag,
          c.client_key,
          c.company_logo,
          c.company_name,
          c.company_product_key,
          c.external_company_reference,
          c.last_session_date ? new Date(c.last_session_date) : null,
          c.owner_flag,
          c.pending_owner_flag,
          c.pending_subscription_key,
          c.pending_subscription_contact_name,
          c.pending_subscription_contact_email,
          c.product_key,
          c.product_name,
          c.sample_company,
          c.subscription_key,
          c.subscription_billing_name,
          c.subscription_billing_email,
          c.no_subscription_access_flag,
          c.suspended_flag,
          c.trial_days_remaining,
          c.trial_expired_flag,
          c.subscribed_flag,
          c.user_access_key,
          account_subscription,
          c.has_company_settings_access,
          c.number_of_employees,
          c.has_payhero_shift_access,
          c.incomplete_onboarding_steps,
          c.industry_key,
          c.auto_promo_code
        );
      case 'Invoxy':
        return new InvAccount(
          c.access_deactivated,
          c.admin_flag,
          c.allow_user_access,
          c.allow_external_connect,
          c.allow_user_access || c.suspended_flag, // allow_account_select - suspended accounts can be selected to pop invoice modal
          c.cancelled_flag,
          c.client_key,
          c.company_logo,
          c.company_name,
          c.company_product_key,
          c.external_company_reference,
          c.last_session_date ? new Date(c.last_session_date) : null,
          c.owner_flag,
          c.pending_owner_flag,
          c.pending_subscription_key,
          c.pending_subscription_contact_name,
          c.pending_subscription_contact_email,
          c.product_key,
          c.product_name,
          c.sample_company,
          c.subscription_key,
          c.subscription_billing_name,
          c.subscription_billing_email,
          c.no_subscription_access_flag,
          c.suspended_flag,
          c.trial_days_remaining,
          c.trial_expired_flag,
          c.subscribed_flag,
          c.user_access_key,
          account_subscription,
          c.has_company_settings_access,
          c.number_of_people,
          c.auto_promo_code
        );
      case 'Karmly':
        return new KmAccount(
          c.access_deactivated,
          c.admin_flag,
          c.allow_user_access,
          c.allow_external_connect,
          c.allow_user_access || c.suspended_flag, // allow_account_select - suspended accounts can be selected to pop invoice modal
          c.cancelled_flag,
          c.client_key,
          c.company_logo,
          c.company_name,
          c.company_product_key,
          c.external_company_reference,
          c.last_session_date ? new Date(c.last_session_date) : null,
          c.owner_flag,
          c.pending_owner_flag,
          c.pending_subscription_key,
          c.pending_subscription_contact_name,
          c.pending_subscription_contact_email,
          c.product_key,
          c.product_name,
          c.sample_company,
          c.subscription_key,
          c.subscription_billing_name,
          c.subscription_billing_email,
          c.no_subscription_access_flag,
          c.suspended_flag,
          c.trial_days_remaining,
          c.trial_expired_flag,
          c.subscribed_flag,
          c.user_access_key,
          account_subscription,
          c.has_company_settings_access,
          c.number_of_people,
          c.auto_promo_code
        );
      default:
        return null;
    }
  }

  getActiveAccountProducts(): { product_value: ProductValue, product_name: ProductName }[] {
    const product_set: Set<ProductValue> = new Set();
    const products: { product_value: ProductValue, product_name: ProductName }[] = [];

    for (const account of this.accounts) {
      product_set.add(account.product_value);
    }
    for (const product_value of product_set) {
      products.push({
        product_value,
        product_name: ProductService.getProductName(product_value)
      });
    }

    return products;
  }

  private _reloadClientAccountMap() {
    this.clientAccountsMap = {};

    for (const account of this.accounts) {
      if (!!account.client_key) {
        if (!this.clientAccountsMap[account.client_key]) {
          this.clientAccountsMap[account.client_key] = [];
        }

        this.clientAccountsMap[account.client_key].push(account);
      }
    }

    for (const client_key of Object.keys(this.clientAccountsMap)) {
      SortUtilService.sortList(this.clientAccountsMap[client_key], 'company_name', 'product_name');
    }
  }

  validateAccountCreation(
    is_demo_account: boolean,
    product: ProductValue,
    company_name: string,
    user_terms_agreed: boolean,
    contact_phone?: string
  ): boolean {
    if (is_demo_account) {
      return !!product &&
        // Partner logins can't create Karmly accounts
        !(this.userService.user_access?.partner_flag && product === 'KARMLY');
    }
    else {
      return !!product &&
        // Partner logins can't create Karmly accounts
        !(this.userService.user_access?.partner_flag && product === 'KARMLY') &&
        (!!company_name || product === 'KARMLY') &&
        (user_terms_agreed || (product !== 'DROPPAH' && product !== 'KARMLY'));
    }
  }

}
