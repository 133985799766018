
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { NgxMaskModule } from 'ngx-mask';

import { ErrorModalComponent } from './error-modal/error-modal.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

import { PipesModule } from './../pipes/pipes.module';
import { InitialiseTwoFactorModalComponent } from './initialise-two-factor-modal/initialise-two-factor-modal.component';
import { SubscribeModalComponent } from './subscribe-modal/subscribe-modal.component';
import { UpdatePaymentMethodModalComponent } from './update-payment-method-modal/update-payment-method-modal.component';
import { AddClientNoteModalComponent } from './add-client-note-modal/add-client-note-modal.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { AddAccountModalComponent } from './add-account-modal/add-account-modal.component';
import { ManageClientStaffAccessModalComponent } from './manage-client-staff-access-modal/manage-client-staff-access-modal.component';
import { TransferOwnershipModalComponent } from './transfer-ownership-modal/transfer-ownership-modal.component';
import { ManageStaffRoleModalComponent } from './manage-staff-role-modal/manage-staff-role-modal.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { UserAccessAgreementsModalComponent } from './user-access-agreements-modal/user-access-agreements-modal.component';
import { CancelAccountModalComponent } from './cancel-account-modal/cancel-account-modal.component';
import { PhEmployeeModalComponent } from './ph-employee-modal/ph-employee-modal.component';
import { PhImportModalComponent } from './ph-import-modal/ph-import-modal.component';
import { PhEmployeeInfoModalComponent } from './ph-employee-info-modal/ph-employee-info-modal.component';
import { PayInvoiceModalComponent } from './pay-invoice-modal/pay-invoice-modal.component';
import { UserAccessModalComponent } from './user-access-modal/user-access-modal.component';


@NgModule({
  declarations: [
    ErrorModalComponent,
    SuccessModalComponent,
    ConfirmModalComponent,
    InitialiseTwoFactorModalComponent,
    SubscribeModalComponent,
    UpdatePaymentMethodModalComponent,
    AddClientNoteModalComponent,
    FormModalComponent,
    AddAccountModalComponent,
    ManageClientStaffAccessModalComponent,
    TransferOwnershipModalComponent,
    ManageStaffRoleModalComponent,
    ChangePasswordModalComponent,
    UserAccessAgreementsModalComponent,
    CancelAccountModalComponent,
    PhEmployeeModalComponent,
    PhImportModalComponent,
    PhEmployeeInfoModalComponent,
    PayInvoiceModalComponent,
    UserAccessModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ComponentsModule,
    NgxMaskModule
  ],
  exports: [
    ErrorModalComponent,
    SuccessModalComponent,
    ConfirmModalComponent,
    SubscribeModalComponent,
    AddClientNoteModalComponent,
    TransferOwnershipModalComponent
  ]
})
export class ModalsModule { }
