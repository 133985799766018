import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { DbUtilService } from './../db-util/db-util.service';
import { AuthService } from '../auth/auth.service';
import { ProductService } from '../product/product.service';
import { UserAccess } from '../../models/user-access/user-access';
import { SocketService } from './../socket/socket.service';
import { StateDataService } from './../state-data/state-data.service';
import { IntegrationSocketEventType, UserAccessAgreements, UserAccessAgreementFlags, LoginIntegration, IntegrationType, ProductValue, ProductName, Country } from './../../app.types';
import { cloneDeep, clone, flatMap } from 'lodash-es';
import { CoreUtilService } from '../core-util/core-util.service';

export type UserServiceEventType = (
  IntegrationSocketEventType |
  'PHONE_VERIFICATION_TOGGLED' |
  'PHONE_VERIFICATION_COUNTDOWN'
);

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userAccess: UserAccess = null;

  phone_verification_countdown: number = null;
  phone_verification_interval: any = null;

  private _events: Record<UserServiceEventType, Subject<any>> = {
    Integration_Login_Success: new Subject<void>(),
    Integration_Connection_Exists: new Subject<void>(),
    Integration_Connection_Complete: new Subject<void>(),
    Integration_Signup_Complete: new Subject<void>(),
    Integration_Error: new Subject<void>(),
    Integration_Login_Not_Found: new Subject<void>(),
    PHONE_VERIFICATION_TOGGLED: new Subject<void>(),
    PHONE_VERIFICATION_COUNTDOWN: new Subject<number>()
  };

  countries: Country[] = [];

  serviceSetup: boolean = false;
  private _socketEventsSetup: boolean = false;

  constructor(
    public dbUtilService: DbUtilService,
    public authService: AuthService,
    public productService: ProductService,
    public stateDataService: StateDataService,
    public socketService: SocketService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {

        Promise.all([
          this.loadUserAccessDetails(),
          this.loadCountries()
        ])
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => reject(err));
      }
    });
  }

  clearServiceData() {
    this.userAccess = null;
    this.serviceSetup = false;
  }

  subscribeToEvent(
    event_type: UserServiceEventType,
    callback: (event_data: any) => void
  ): Subscription {
    if (!!this._events[event_type]) {
      return this._events[event_type]
        .asObservable()
        .subscribe((event_data) => { callback(event_data); });
    }
  }

  getPhoneVerificationDisabled(): boolean {
    return this.phone_verification_interval !== null;
  }

  getPhoneVerificationCountdown(): number {
    return this.phone_verification_countdown;
  }

  getCountryForPhoneNumber(phone_number: string): Country {
    if (!phone_number) return null;

    for (const country of this.countries) {
      if (
        !!country.calling_code &&
        phone_number.indexOf(country.calling_code) === 0
      ) {
        return country;
      }
    }
    return null;
  }

  getCountry(country_key: string): Country {
    return this.countries.find(country => country.country_key === country_key) || null;
  }

  getCountries(): Country[] {
    return clone(this.countries);
  }

  private _initSocketEvents(): void {
    this.socketService.subToEvent('Integration_Login_Success', 'UserService', (event) => {
      this._events.Integration_Login_Success.next(event);
    });
    this.socketService.subToEvent('Integration_Login_Not_Found', 'UserService', (event) => {
      this._events.Integration_Login_Not_Found.next(event);
    });
    this.socketService.subToEvent('Integration_Connection_Complete', 'UserService', (event) => {
      this._events.Integration_Connection_Complete.next(event);
    });
    this.socketService.subToEvent('Integration_Connection_Exists', 'UserService', (event) => {
      this._events.Integration_Connection_Exists.next(event);
    });
    this.socketService.subToEvent('Integration_Signup_Complete', 'UserService', (event) => {
      this._events.Integration_Signup_Complete.next(event);
    });
    this.socketService.subToEvent('Integration_Error', 'UserService', (event) => {
      this._events.Integration_Error.next(event);
    });
    this._socketEventsSetup = true;
  }

  getIntegrationEvent(event_type: IntegrationSocketEventType): Observable<void> {
    if (!this._socketEventsSetup) {
      this._initSocketEvents();
    }
    return this._events[event_type].asObservable();
  }

  getIntegrationConnectionExistsMessage(integration_name: string): string {
    let name = integration_name;

    if (!name) name = 'integration';

    return 'Your ' + name + ' Account is connected to another Account. Please try a different ' + name + ' Account or disconnect your linked Account and try again.';
  }

  get user_access(): UserAccess {
    return cloneDeep(this.userAccess);
  }

  get user_access_key(): number {
    return clone(this.userAccess.user_access_key);
  }

  get is_partner(): boolean {
    return clone(this.userAccess?.partner_flag || false);
  }

  get has_partner_admin_access(): boolean {
    return this.userAccess?.partner_owner_flag || this.userAccess?.partner_admin_flag;
  }

  get is_partner_owner(): boolean {
    return clone(this.userAccess?.partner_owner_flag || false);
  }

  get has_subscription(): boolean {
    return clone(this.userAccess?.subscription_flag || false);
  }

  get is_droppah_candidate(): boolean {
    return this.userAccess?.droppah_candidate_flag;
  }

  initialiseTwoFactor() {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPost('user_access/init2fa', null, true)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  verifyTwoFactor(code): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPost('user_access/verify2fa', { code }, true)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  disableTwoFactor(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIPost('user_access/disable2fa', null, true)
        .then(() => {
          this.loadUserAccessDetails()
            .finally(() => {
              resolve();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  openIntegrationWindow() {
    const tab = window.open(null, '', 'height=750, left=500, top=110, width=550');

    const tabStyles = 'width: 40px; position: absolute; margin: auto; top: 0; bottom: 0; left: 0; right: 0;';
    const tabContents = '<img style="' + tabStyles + '" src="assets/imgs/App_Loader.gif">';

    tab.document.write(tabContents);

    return tab;
  }

  getGoogleLoginURI(auth_type: string, verification_token: string, product_name: ProductName, country_key: string, additional_data: string, marketing_additional_data: string) {
    return new Promise<any>((resolve, reject) => {

      const params: any = {
        auth_type,
        verification_token,
        product_name,
        country_key,
        additional_data,
        marketing_additional_data,
        socket_id: this.socketService.socket_id,
        session_key: this.authService.session_key // This is only expected to exist for the connect state
      };

      this.dbUtilService.APIGet('google/auth', params, auth_type === 'CONNECT', 'text')
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getXeroAuthURI(auth_type: string, token: string, product_name: ProductName, country_key: string, referral_code: string, additional_data, marketing_additional_data: string) {
    return new Promise<any>((resolve, reject) => {

      const params: any = {
        auth_type,
        token,
        product_name,
        country_key,
        referral_code,
        additional_data,
        marketing_additional_data,
        socket_id: this.socketService.socket_id,
        session_key: this.authService.session_key
      };

      // Remove any null values here so they aren't received as stringified nulls on the API side
      for (const field in params) {
        if (!params[field]) delete params[field];
      }

      this.dbUtilService.APIGet('xero/auth', params, auth_type === 'CONNECT', 'text')
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  googleConnect(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getGoogleLoginURI('CONNECT', null, null, null, null, null)
        .then((URL) => {
          resolve(URL);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  googleDisconnect(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIPost('google/disconnect', null, true)
        .then(() => {
          this.loadUserAccessDetails()
            .finally(() => {
              resolve();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  xeroConnect(product_name: ProductName): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getXeroAuthURI('CONNECT', null, product_name, null, null, null, null)
        .then((URL) => {
          resolve(URL);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  xeroDisconnect(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIPost('xero/disconnect', null, true)
        .then(() => {
          this.loadUserAccessDetails()
            .finally(() => {
              resolve();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateUserAccessDetails(
    user_access: UserAccess,
    new_password: string = null,
    old_password: string = null
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {

      if (new_password && new_password.length < 8) {
        return reject({ message: 'Password must be at least 8 characters' });
      }
      const data = user_access.formatForPosting(this.productService.current_product, old_password, new_password);

      this.dbUtilService.APIPost('user_access', data, true)
        .then((result) => {
          this.loadUserAccessDetails()
            .finally(() => {
              const verification_email = result?.length ? result[0].verification_email : null;
              let message = null;

              if (!!verification_email) {
                message = 'A verification email has been sent to ' + verification_email
                  + '. Please check your inbox to finish updating your login email.';
              }
              resolve(message);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateUserAccessImage(
    user_access: UserAccess, imageData: any = null
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data = user_access.formatImageDataForPosting(imageData);
      this.dbUtilService.APIPost('user_access/image', data, true)
        .then((result) => {
          this.loadUserAccessDetails()
            .finally(() => {

              resolve(null);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  emailPasswordResetPayHero(email: string) {
    return new Promise<void>((resolve, reject) => {
      const data = {
        registered_email: email,
        destination: 'PayHero_Portal'
      };

      this.dbUtilService.APIPostPayHero('user/sendReset', data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  emailPasswordReset(email: string) {
    return new Promise<void>((resolve, reject) => {
      const data = {
        registered_email: email,
        product_name: this.productService.current_product
      };

      this.dbUtilService.APIPost('user_access/sendReset', data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  loadUserAccessAgreements(): Promise<UserAccessAgreements> {
    return new Promise<UserAccessAgreements>((resolve, reject) => {

      this.dbUtilService.APIGet('user_access/agreements')
        .then((user_access_agreements: UserAccessAgreements) => {
          resolve(user_access_agreements);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateUserAccessAgreements(
    agreement_flags: Partial<Record<UserAccessAgreementFlags, boolean>>
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIPost('user_access/agreements', agreement_flags)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  loadUserAccessDetails(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIGet('user_access')
        .then((user) => {
          if (user !== null) {
            this.userAccess = this.setupUserAccess(user);
            this.stateDataService.intialiseLocalStorage(this.userAccess.user_access_key);
            this.authService.user_access_key = this.userAccess.user_access_key;
            resolve();
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadCountries(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIGet('country')
        .then((countries) => {
          this.countries = this.setUpCountries(countries);
          resolve();
        })
        .catch((err) => reject());
    });
  }

  loadUserDetails() {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('user')
        .then((res) => {
          const user = res.length ? res[0] : null;

          if (user !== null) {
            user.first_name = user.full_name ? user.full_name.split(' ')[0] : null;
            resolve(user);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadPartnerDetails() {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('partner')
        .then((res) => {
          resolve(res.length ? res[0] : null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private _startPhoneVerificationCountdown() {
    if (!this.getPhoneVerificationDisabled()) {
      this.phone_verification_countdown = 20;
      this._events.PHONE_VERIFICATION_COUNTDOWN.next(this.phone_verification_countdown);

      this.phone_verification_interval = setInterval(() => {
        this.phone_verification_countdown--;
        this._events.PHONE_VERIFICATION_COUNTDOWN.next(this.phone_verification_countdown);

        if (this.phone_verification_countdown <= 0) {
          this.phone_verification_countdown = null;
          clearInterval(this.phone_verification_interval);
          this.phone_verification_interval = null;

          this._events.PHONE_VERIFICATION_TOGGLED.next(null);
        }
      }, 1000);

      this._events.PHONE_VERIFICATION_TOGGLED.next(null);
    }
  }

  deleteUserAccessMobile(): Promise<void> {
    return new Promise((resolve, reject) => {

      const data = {
        contact_phone: '',
        product_name: this.productService.current_product
      };

      this.dbUtilService.APIPost('user_access', data, true)
        .then(() => {
          this.userAccess.contact_phone = null;
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  updateUserAccessMobile(
    country_calling_code: string,
    contact_phone: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {

      contact_phone = CoreUtilService.formatMobileNumberForPosting(
        country_calling_code,
        contact_phone
      );

      const data = {
        contact_phone,
        product_name: this.productService.current_product
      };

      this.dbUtilService.APIPost('user_access', data, true)
        .then(() => {
          this.userAccess.contact_phone = contact_phone;
          resolve(contact_phone);
        })
        .catch((err) => reject(err));
    });
  }

  verifyPhoneNumber(
    country_calling_code: string,
    mobile_number: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.getPhoneVerificationDisabled()) {
        this._startPhoneVerificationCountdown();

        const phone_number = CoreUtilService.formatMobileNumberForPosting(
          country_calling_code,
          mobile_number
        );

        const data: any = {
          phone_number
        };

        this.dbUtilService.APIPost('user_access/verify_phone/start', data)
          .then(() => resolve())
          .catch(() => reject());
      }
      else {
        reject();
      }
    });
  }

  verifyOTP(callingCode: string, phone_number: string, otp: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const data: any = {
        phone_number: callingCode + phone_number,
        otp
      };

      this.dbUtilService.APIPost('user_access/verify_phone/check', data)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setupUserAccess(u: any): UserAccess {
    // Turn [ { integration_type: 'VALUE' }, ... ] into a set containing ( 'VALUE', ... )
    const loginIntegrations: Set<IntegrationType> = new Set(flatMap(u.login_integration_types, (type => {
      return type.integration_type;
    })));

    return new UserAccess(
      u.contact_phone,
      u.full_name,
      loginIntegrations,
      u.partner_flag,
      u.partner_owner_flag,
      u.partner_admin_flag,
      u.password_set,
      u.registered_email,
      u.subscription_flag,
      u.two_factor_enabled,
      u.two_factor_required,
      u.user_access_key,
      u.username,
      u.verification_email,
      u.intercom_settings,
      u.profile_picture,
      u.thumbnail_picture,
      u.droppah_candidate_flag
    );
  }

  setUpCountries(data: any[]): Country[] {
    const countries: Country[] = [];

    for (const d of data) {
      const country = this.setUpCountry(d);

      if (!!country) {
        countries.push(country);
      }
    }
    return countries;
  }

  setUpCountry(d: any): Country {

    const country_provinces = [];
    for(const cp of d.country_provinces) {
      country_provinces.push({
        country_province_key: cp.country_province_key,
        country_key: cp.country_key,
        province_name: cp.province_name,
        timezone_name: cp.timezone_name
      });
    }

    const country: Country = {
      country_key: d.country_key,
      country_name: d.country_name,
      money_symbol: d.money_symbol,
      timezone_name: d.timezone_name,
      calling_code: d.calling_code || '',
      billing_currency: d.billing_currency,
      country_provinces: country_provinces
    };

    return country;
  }


}
